import { Component, OnInit } from '@angular/core';
import { NavbarService } from './navbar.service';
import { AuthenticationService } from 'src/app/services';
import { AccountService } from 'src/app/_services';
import { Router } from '@angular/router';
import { CookieService } from 'ng2-cookies';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

cookieValue:string;
currentHeaderPaths;
userEmail:string;
userId:number;
userName:string;
comment:any;
reqsent:boolean = false;
commentform:boolean = true;
userid: number;
message:string;
notilen:number;
notificationarray=[];
selectednotificationid:any;


constructor(
  private cookie: CookieService,
  private router :Router,
  private navBarService: NavbarService,
  private authenticationService: AuthenticationService,
  private accountService:AccountService,) { 
}

  ngOnInit() {
  
    this.authenticationService.currentUserValue
    if (this.authenticationService.currentUserValue) {
      this.userEmail = this.authenticationService.currentUserValue.emailid;
      this.userName  = this.authenticationService.currentUserValue.username;
      this.userId = this.authenticationService.currentUserValue.userid;
    }
    // this.userId = 1030
    this.navBarService.getNotification(this.userId).subscribe(notificationres=>{
      if(notificationres.unreadCount > 0){
      this.notilen=notificationres.unreadCount;
      console.log(this.notilen,'length')
      } 
    })
    this.currentHeaderPaths = [{title:"dashboard",path:'/dashboard'}];
    this.navBarService.getHeaderPath().subscribe(paths=>{
      
      if(paths){
        this.currentHeaderPaths = paths;
      }else{
        this.currentHeaderPaths = [{title:'dashboard',path:'/dashboard'}]
      }
    });
  }

  logout() {
  
    this.cookieValue = this.cookie.get('buzz');
    this.authenticationService.logout();
	this.accountService.logout();
	
    this.router.navigate(['/login'] , { queryParams: { buzz: this.cookieValue } } );
}
//this is for contact us model oprening on click
contactusload(){
  this.commentform=true;
  this.reqsent = false;
}
//this is for contact us feedback details
contactus(textarea:string){
  if(textarea == ''){
    alert('please provide');
  }
  else{
  this.comment = textarea;
  this.navBarService.postfeedback(this.userId,this.comment).subscribe(feedbackres=>{
    console.log(feedbackres,'feedbackres');
    if (feedbackres){
      this.reqsent = true;
      this.message = feedbackres.message;
      console.log(this.message,"message");
      this.commentform = false;
    }
  })
  // console.log(this.comment,"textareacomment");
  }
  }
// This is for notifications

notification(){
  // this.userId = 1030
  this.navBarService.getNotification(this.userId).subscribe(notificationres=>{
    console.log(notificationres,'notificationres');
    this.notificationarray=notificationres.data;
    console.log(this.notificationarray,'notificationresarray');
    
    
  
    
  })
}
// selectedsource(e:any){
//   const targetIndex = e.target;
//     this.selectednotificationid= targetIndex.dataset.empid;

// alert(this.selectednotificationid);
// console.log(this.selectednotificationid,'selnot');
// this.navBarService.updateNotification(this.userId,this.selectednotificationid).subscribe(updatenoteres=>{
//   console.log(updatenoteres,'this.updatenoteres')
// })
// }

selectedsource(id:any,sourceid:any){

  console.log(id,'id')
  this.selectednotificationid = id
  // this.userId = 1030
  console.log(this.userId,'userid');
  console.log(this.selectednotificationid,'notify id');
  this.navBarService.updateNotification(this.userId,this.selectednotificationid).subscribe(updatenoteres=>{
    console.log(updatenoteres,'this.updatenoteres')
    })
    this.navBarService.getNotification(this.userId).subscribe(TrendResult =>{
      if(TrendResult.unreadCount > 0){
        this.notilen=TrendResult.unreadCount;
        console.log(this.notilen,'length before navigate')
        }
        let routerParamObj={filterBy:'source',value:sourceid};
        this.router.navigate(['/reviews'], { queryParams: routerParamObj});
    })
   
}

}

