import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { shareReplay } from 'rxjs/operators';
const BASE_URL = environment.BASE_URL();

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

constructor(private http: HttpClient) { }

getSoicalMediaConnectStatus(userId: number) {
    return this.http.get<any>(`${BASE_URL}/socialMediaCheck?userid=${userId}`).pipe(shareReplay(1));
}

getTwitterAuthLink(userId: any) {
    return this.http.get<any>(`${BASE_URL}/generateTwitterAuthLink?userid=${userId}`).pipe(shareReplay(1));
}

//added by revathy
getFacebookAuthLink(userId: any) {
  return this.http.get<any>(`${BASE_URL}/generateFacebookAuthLink?userid=${userId}`).pipe(shareReplay(1));
}
//added by revathy

disConnectSocialLink(userId: number, type:string) {
  return this.http.get<any>(`${BASE_URL}/deleteSocialLink?userid=${userId}&type=${type}`).pipe(shareReplay(1));
}

sendCapturedTwitterInfo(userInfo:any){
  return this.http.post<any>(`${BASE_URL}/twitterCapture`, userInfo).pipe(shareReplay(1));
}

//added by revathy for facebook
sendCapturedFacebookInfo(userInfo:any){
  return this.http.post<any>(`${BASE_URL}/facebookCapture`, userInfo).pipe(shareReplay(1));
}
//added by revathy for facebook

postMessageAtSocialMedia(postData:any){
  return this.http.post<any>(`${BASE_URL}/socialMediaPost`, postData).pipe(shareReplay(1));
}

postReplyToSocialComment(replyData:any){
  return this.http.post<any>(`${BASE_URL}/socialMediaReply`, replyData).pipe(shareReplay(1));
}

}
