import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { shareReplay } from 'rxjs/operators';
const BASE_URL = environment.BASE_URL();

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

constructor(private http: HttpClient) { }

 getReviewsPageMasterData(userId:number){
 
  return this.http.get<any>(`${BASE_URL}/reviewsPageMasterData?userid=${userId}`).pipe(shareReplay(1));
 }
getFBUserPostsData(accessToken:any,fbUserId:any){
return this.http.get<any>(`https://graph.facebook.com/v16.0/${fbUserId}/posts?access_token=${accessToken}`).pipe(shareReplay(1));
}
getFBUserPagesData(accessToken:any,fbUserId:any){
return this.http.get<any>(`https://graph.facebook.com/${fbUserId}/accounts?fields=name,access_token&access_token=${accessToken}`).pipe(shareReplay(1));
}
getFBUserPagePostsCommentsData(fbPageAccessToken:any,fbPageId:any){
return this.http.get<any>(`https://graph.facebook.com/v16.0/${fbPageId}?fields=posts{comments{comments,message,likes,from,created_time},message,likes,from,parent,created_time}&access_token=${fbPageAccessToken}`).pipe(shareReplay(1));
}
getFBUserPagePostsCommentsDetailsData(fbPageAccessToken:any,pagePostCommentId:any){
return this.http.get<any>(`https://graph.facebook.com/v16.0/${pagePostCommentId}?fields=comments{comments,message,likes,from,created_time}&access_token=${fbPageAccessToken}`).pipe(shareReplay(1));
}
getFBUserPostsCommentsPublish(pagePostCommentId:any,publishData:object){
  return this.http.post<any>(`https://graph.facebook.com/v16.0/${pagePostCommentId}/comments`,publishData).pipe(shareReplay(1));
 }


//FB V2 Start
getFBAllPosts(fbPageAccessToken:any,fbPageId:any){
return this.http.get<any>(`https://graph.facebook.com/v16.0/${fbPageId}?fields=posts&access_token=${fbPageAccessToken}`).pipe(shareReplay(1));
}
getFBPostwiseCommentsData(fbPageAccessToken:any,postId:any){
return this.http.get<any>(`https://graph.facebook.com/v16.0/${postId}/comments?fields=from,message,likes,parent,created_time,comments{created_time,from,message,likes}&summary=1&filter=toplevel&access_token=${fbPageAccessToken}`).pipe(shareReplay(1));
}
//FB V2 END
 
 //For Instagram
 getFBPageDetails(accessToken:any){
return this.http.get<any>(`https://graph.facebook.com/me/accounts?access_token=${accessToken}`).pipe(shareReplay(1));
}
 getInstaBusinessAccountDetails(fbPageId:any,accessToken:any){
return this.http.get<any>(`https://graph.facebook.com/${fbPageId}?fields=instagram_business_account&access_token=${accessToken}`).pipe(shareReplay(1));
}
getInstaBusinessAccountMediaDetails(instaBusinessId:any,accessToken:any){
return this.http.get<any>(`https://graph.facebook.com/${instaBusinessId}/media?access_token=${accessToken}`).pipe(shareReplay(1));
}
getInstaBusinessAccountMediaComments(instaMediaId:any,accessToken:any){
return this.http.get<any>(`https://graph.facebook.com/${instaMediaId}/comments?access_token=${accessToken}`).pipe(shareReplay(1));
}
getInstaBusinessAccountMediaCommentsDetails(instaMediaCommentId:any,accessToken:any){
return this.http.get<any>(`https://graph.facebook.com/${instaMediaCommentId}?fields=from,like_count,replies{from,text,timestamp},text,timestamp&access_token=${accessToken}`).pipe(shareReplay(1));
}
getInstaUserPostsCommentsPublish(pagePostCommentId:any,publishData:object){
  return this.http.post<any>(`https://graph.facebook.com/v16.0/${pagePostCommentId}/replies`,publishData).pipe(shareReplay(1));
 }
 searchBusinessReviewsData(seachFilterObj:any){

  let encodedName = encodeURI(seachFilterObj.skuIds.join("~~"));

  let reqParameters = `userid=${seachFilterObj.userid}
  &startno=${seachFilterObj.startno}&noRecords=${seachFilterObj.noRecords}`;
  
  if(seachFilterObj.locationIds && seachFilterObj.locationIds.length){
    reqParameters +=  `&locationIds=${seachFilterObj.locationIds.join("|")}`
  }
  if(seachFilterObj.skuIds && seachFilterObj.skuIds.length){
    reqParameters +=  `&skuIds=`+encodedName
  }
  if(seachFilterObj.sourceIds && seachFilterObj.sourceIds.length){
    reqParameters +=  `&sourceIds=${seachFilterObj.sourceIds.join("|")}`
  }
  if(seachFilterObj.ratings && seachFilterObj.ratings.length){
    reqParameters +=  `&ratings=${seachFilterObj.ratings.join("|")}`
  }
  if(seachFilterObj.sentiments && seachFilterObj.sentiments.length){
    reqParameters +=  `&sentiments=${seachFilterObj.sentiments.join("|")}`
  }
  if(seachFilterObj.fromDate){
    reqParameters +=  `&fromDate=${seachFilterObj.fromDate}`
  }
  if(seachFilterObj.toDate){
    reqParameters +=  `&toDate=${seachFilterObj.toDate}`
  }
  if(seachFilterObj.keywords && seachFilterObj.keywords.trim().length){
    reqParameters +=  `&keywords=${seachFilterObj.keywords}`
  }
  //Added by Aravindh start
  if(seachFilterObj.bisIds && seachFilterObj.bisIds.length){
    reqParameters +=  `&bisIds=${seachFilterObj.bisIds.join("~~")}`
  }
  if(seachFilterObj.locIds && seachFilterObj.locIds.length){
    //reqParameters +=  `&locIds=${seachFilterObj.locIds.join("|")}`
  reqParameters +=  `&locIds=${seachFilterObj.locIds}`
  }  
  console.log("reqParameters");
  console.log(reqParameters);
  //Added by Aravindh end
  return this.http.get<any>(`${BASE_URL}/reviewsPageData?${reqParameters}`).pipe(shareReplay(1));
 }
//Google Start
getGoogleAccountCreation(postData:object){
  return this.http.post<any>(`https://mybusinessaccountmanagement.googleapis.com/v1/accounts/`,postData).pipe(shareReplay(1));
 }
//Google End
}
