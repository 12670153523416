// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //HOST: 'beta.buzzsense.io',
  HOST: 'beta.buzzsense.io',
  URLVersion: '/apimasterbeta/api/v1',
  BASE_URL(){
    return `https://${environment.HOST}${environment.URLVersion}`
  },
  cryptoSecretKey: 'G&%65dsfV*er97(87RE(()^%$#f3R^D',
  basicAuthKey: 'YXBpbWFzdGVyX2tleTpabTl5WVhCd2JHbGpZWFJwYjI1aFkyTmxjM00=',
  recaptcha: {
    siteKey: '6LcrnIMjAAAAABvWJusVMHacwN3wCV4v2hofpQ_w',
  },
  apiUrl: 'http://localhost:4200',
  facebookAppId: '230267049413550' // dev APP ID
  //facebookAppId: '585366920371125'//New APP ID prod
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
