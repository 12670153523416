import { BrowserModule } from '@angular/platform-browser';
import { NgModule,APP_INITIALIZER } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import { Bell } from 'angular-feather/icons'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ng2-cookies';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { SidebarComponent } from './elements/sidebar/sidebar.component';
import { NavbarComponent } from './elements/navbar/navbar.component';
import { FooterComponent } from './elements/footer/footer.component';
import { NavbarService } from './elements/navbar/navbar.service';
import { SharedModule } from './shared/shared.module';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor, fakeBackendProvider,ExpiryInterceptor,appInitializer } from './_helpers';
import { AccountService } from './_services';
import {ToastModule} from 'primeng/toast';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from "@ng-select/ng-select";
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';

import {
  NgxUiLoaderModule,
} from 'ngx-ui-loader';

const icons = {
  Bell
};

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    AuthLayoutComponent,
    SidebarComponent,
    NavbarComponent,
    FooterComponent
  ],
  imports: [
    HttpClientModule ,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FeatherModule.pick(icons),
    SharedModule,
    NgxUiLoaderModule,
    ToastModule,
    FormsModule,
    NgSelectModule,
	ModalModule,
  ],
  exports:[FeatherModule,SharedModule],
  providers: [NavbarService, 
	{ provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
    { provide: HTTP_INTERCEPTORS, useClass: ExpiryInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // fakeBackendProvider
	// provider used to create fake backend
    fakeBackendProvider,
    CookieService,BsModalService
],
  bootstrap: [AppComponent]
})
export class AppModule { }
