import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';
const BASE_URL = environment.BASE_URL();

@Injectable({
  providedIn: 'root'
})
export class AccountService {

constructor(private http: HttpClient) { }
 
 getSettingsProfileData(userId:number){

  return this.http.get<any>(`${BASE_URL}/settingsPage?userid=${userId}`).pipe(shareReplay(1));
 }

 saveBusinessProfile(setting:object){
  return this.http.post<any>(`${BASE_URL}/saveBusinessProfileAccount`,setting).pipe(shareReplay(1));
 }
  getUserPhoneData(userIdData:object){
  return this.http.post<any>(`${BASE_URL}/getuserphonedata`,userIdData).pipe(shareReplay(1));
 }

 getBusinessProfileData(userId:number){
  
  return this.http.get<any>(`${BASE_URL}/settingsPageData?userid=${userId}`).pipe(shareReplay(1));
 }
 

}
