import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { relativeTimeThreshold } from 'moment';
import { SharedService } from '../shared.service';
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

const Ratings = ["5", "4", "3", "2", "1"];

const allMonthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

const sentiments = [
    "Positive",
    "Neutral",
    "Negative"
];

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit, AfterViewInit, OnDestroy {
    chartTypes = [
        { name: 'Column', value: 'column' },
        { name: 'Line', value: 'line' },
    ];
    count = 0;
    @Input('reDrawChart') reDrawChart;
    @Input('trendsData') aspect;
    @Input('locationData') locationMaster;
    @Input('isOverAllChart') isOverAllChart;
    @Input('excelData') excelData;
	@Input('stateData') stateMaster;
    @Input('openPopUpChartfromLeft')
    set openPopUpChartfromLeft(value: boolean) {
        if (value == true) {
            this.changeOption(this.popupChartId, 'rating', true);
        }
    };
    chart: any;
    insideUpdate: boolean = true;
    popUpUpdate: boolean = false;
    monthLimit: number = 5;
    limitedMonthNames = [];
    reviewsXaxisData: any;
    ratingXaxisData: any;
    sentimentXaxisData: any;

    reviewsYaxisData: any;
    ratingYaxisData: any;
    sentimentYaxisData: any;
    curPopUpChartType = { name: 'Column', value: 'column' };
    curPopUpTab = 'rating';

    Highcharts = Highcharts;
    chartOptions = {};
    mainChartId: string;
    popupChartId: string;
    currentOption: string;

    constructor(
        private sharedService: SharedService,
        private router:Router
    ) { }

    ngOnInit(): void {
        this.mainChartId = this.aspect['aspectid'] + '_main_chart_id';
        this.popupChartId = this.aspect['aspectid'] + '_popup_chart_id';  
      
        this.ratingXaxisData = Ratings;
        this.reviewsXaxisData = this.getSpecifiedMonthNames();
		//console.log("this.aspect.aspectRatingData start");
		//console.log(this.aspect.aspectRatingData);
		//console.log("this.aspect.aspectRatingData end");
        this.ratingYaxisData = this.findYaxisData(this.aspect.aspectRatingData, 'ratings');
        this.reviewsYaxisData = this.findYaxisData(this.aspect.aspectReviewData, 'reviews');
        this.sentimentYaxisData = this.findYaxisData(this.aspect.aspectsentimentData, 'sentiment');
       
        this.chartOptions = {
            chart: {
                type: 'line', //default
                style: {
                    fontFamily: 'Roboto,sans-serif'
                }
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            xAxis: {
                visible: true,
                type: "category",
                title: {
                    style: {
                        fontSize: '12px',
                        fontWeight: '500'
                    },
                    categories: []
                },

                plotOptions: {
                    column: {
                        point: {
                            events: {
                                click: function (event) {
                                    console.log("fsdfsdf", this.x, event);

                                }
                            }
                        },
                        colorByPoint: true
                    },

                }
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontSize: '12px',
                        fontWeight: '500'
                    }
                },

            },
            colors: [
                '#00008B',
                '#006400',
                '#AA4643',
                '#DB843D',
                '#4572A7'
            ],
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true,
                        crop: false,
                        overflow: "none"
                    },
                    enableMouseTracking: false
                },
                series: {
                    events: {
                        click: (event)=> {
                            let value = event.point.category;
                            let filterType = this.currentOption.toLowerCase();
                            console.log("chart event",event,this.currentOption);
                            let routerParamObj={filterBy:filterType,value:value};
                            this.router.navigate(['/reviews'],{ queryParams: routerParamObj})
                        }
                    },
                    showInLegend: false,
                    // lineWidth: ,
                    animation: {
                        duration: 1250,
                        easing: 'easeOutBounce'
                    }
                }
            },
            tooltip: {
                animation: true,
                shared: true
            }
        };
        setTimeout(() => {
            this.changeOption(this.mainChartId, 'rating');
            // this.changeOption(this.mainChartId, 'reviews');
        }, 5000);
      
        // this.changeOption(this.mainChartId,'rating');
    }

    ngAfterViewInit() {

      

        // setTimeout(() => {
        //     this.changeOption(this.mainChartId, 'rating');
        //     // this.changeOption(this.mainChartId, 'reviews');
        // }, 5000);
   
      

        
    }

    changeOption(chartId: string, optionName: string, isPopUpClick = false) {
        console.log("optionName",optionName);
        this.currentOption = optionName;
        switch (optionName) {
            case 'rating': {
                let seriesData = JSON.parse(JSON.stringify(this.ratingYaxisData));		

                this.chartOptions["chart"]["type"] = isPopUpClick ? this.curPopUpChartType.value == 'column' ? 'column' : 'spline' : "column";
                this.chartOptions["xAxis"]["categories"] = Ratings;
                this.chartOptions["yAxis"]["title"]["text"] = "Rating Count";
                this.chartOptions["xAxis"]["title"]["text"] = "Rating";
                this.chartOptions["plotOptions"]["series"]["showInLegend"] = isPopUpClick || this.isOverAllChart || seriesData.length <= 3 ? true : false;
                this.chartOptions["series"] = seriesData;
                this.chartOptions = {...this.chartOptions};
                this.chart = new Highcharts.Chart(chartId, this.chartOptions);
                break;
            }
            case 'reviews': {
                let seriesData = JSON.parse(JSON.stringify(this.reviewsYaxisData));
				
                this.chartOptions["chart"]["type"] = isPopUpClick ? this.curPopUpChartType.value == 'column' ? 'column' : 'spline' : "spline";
                this.chartOptions["xAxis"]["categories"] = this.getSpecifiedMonthNames();
                this.chartOptions["yAxis"]["title"]["text"] = "Reviews Count";
                this.chartOptions["xAxis"]["title"]["text"] = "";
                this.chartOptions["plotOptions"]["series"]["showInLegend"] = isPopUpClick || this.isOverAllChart || seriesData.length <= 3 ? true : false;
                this.chartOptions["series"] = seriesData;
                this.chartOptions = {...this.chartOptions};
                this.chart = new Highcharts.Chart(chartId, this.chartOptions);
                break;
            }
            case 'sentiment': {
                let seriesData = JSON.parse(JSON.stringify(this.sentimentYaxisData));
				
                this.chartOptions["chart"]["type"] = isPopUpClick ? this.curPopUpChartType.value == 'column' ? 'column' : 'spline' : "column";
                this.chartOptions["xAxis"]["categories"] = sentiments;
                this.chartOptions["yAxis"]["title"]["text"] = "Sentiments Count";
                this.chartOptions["plotOptions"]["series"]["showInLegend"] = isPopUpClick || this.isOverAllChart || seriesData.length <= 3 ? true : false;
                this.chartOptions["xAxis"]["title"]["text"] = "";
                this.chartOptions["series"] = seriesData;
                this.chart = new Highcharts.Chart(chartId, this.chartOptions);
                break;
            }
            default: {
                this.changeOption(this.mainChartId, 'rating');
                break;
            }
        }

    }

    getSpecifiedMonthNames() {
        if (this.limitedMonthNames && this.limitedMonthNames.length) {
            return this.limitedMonthNames;
        }

        for (var i = 0; i < this.monthLimit; i++) {
            var curDate = new Date();
            curDate.setMonth(curDate.getMonth() - ((this.monthLimit - 1) - i));
            this.limitedMonthNames.push(allMonthNames[curDate.getMonth()]);
        }
        return this.limitedMonthNames;
    }

    findYaxisData(dataObj, axisFor: string) {
	//console.log(dataObj);
	//console.log(axisFor);
        let Result = [];
        let compareSource: any;
		
		this.isOverAllChart	=true; //added by aravindh	
		let stateMasterChart=[];
		if(this.stateMaster)
		{
			stateMasterChart=this.stateMaster;
		}
        if(this.locationMaster){
            Object.entries(dataObj).forEach(([key, value], index) => {
				//if(this.locationMaster[key]!==undefined)//Added by Aravindh
				//{//Added by Aravindh
					Result[index] = {
						//name: this.isOverAllChart ? key : this.locationMaster[key]["Address"] + ", " + this.locationMaster[key]["city"] + ", " + this.locationMaster[key]["zip"],//commented by Aravindh
						name: this.isOverAllChart && this.locationMaster[key]==undefined ? key : this.locationMaster[key]["branchname"]=='Ecom'?stateMasterChart[this.locationMaster[key]["Address"]]?stateMasterChart[this.locationMaster[key]["Address"]]:this.locationMaster[key]["Address"]:this.locationMaster[key]["Address"],//Added by Aravindh
						//name: this.isOverAllChart && this.locationMaster[key]==undefined ? key : this.locationMaster[key]["Address"],//Added by Aravindh
						data: []
					};

		
					if (axisFor == 'ratings') {
						compareSource = Ratings;
					} else if (axisFor == 'reviews') {
						compareSource = this.limitedMonthNames;
					} else {
						compareSource = sentiments;
					}

					compareSource.forEach((val, ind) => {
						if (Object.keys(value).indexOf(compareSource[ind]) != -1) {
							Result[index]["data"].push(parseInt(value[compareSource[ind]]))
						} else {
							Result[index]["data"].push(0)
						}
					})
					
				//}//Added by Aravindh
            });
        }
        return Result;
    }

    exportAsXLSX(): void {
        let OverAllSheetData = [];

        this.sharedService.emptyMultiPleSheetWorkBook();
        this.sharedService.setExportFileName("ORM_over_all_report");


        if (this.excelData && Object.keys(this.excelData).length) {
            OverAllSheetData = [
                {
                    sheetName: 'Left Section',
                    sheetData: this.leftSectionDataForExport(),
                },
                {
                    sheetName: 'Ratings (All Aspects)',
                    sheetData: this.getDataForExcel(this.excelData["ratingOverAll"], 'ratings', true)
                },
                {
                    sheetName: 'Reviews (All Aspects)',
                    sheetData: this.getDataForExcel(this.excelData["reviewOverAll"], 'reviews', true)
                },
                {
                    sheetName: 'sentiments (All Aspects)',
                    sheetData: this.getDataForExcel(this.excelData["sentimentOverAll"], 'sentiments', true)
                }
            ];

            Object.entries(this.excelData["pageAspectWiseData"]).forEach(([Okey, Allaspect], Oindex) => {
                Object.entries(Allaspect).forEach(([key, aspect], index) => {

                    OverAllSheetData.push({
                        sheetName: (Okey + ' (' + (this.getSheetName(key)) + ')'),
                        sheetData: this.getDataForExcel(aspect, key)
                    })

                })

            });


            OverAllSheetData.forEach((aspect, index) => {
                let isFinished = false;
                if (index == OverAllSheetData.length - 1) {
                    isFinished = true;
                }
                this.sharedService.exportAsMultipleSheetExcelFile(aspect.sheetName, aspect.sheetData, isFinished)
            })
        }

    }
    getSheetName(key): string {
        let result = '';
        if (key == 'ratingOverAll') {
            result = 'Ratings'
        }
        else if (key == 'reviewOverAll') {
            result = 'Reviews'
        }
        else {
            result = 'Sentiments'
        }
        return result;
    }

    leftSectionDataForExport() {
        return this.excelData["leftSection"].map(aspect => {
            return {
                'Aspect': aspect[0],
                'Reviews Count': aspect[1],
                'Overall Rating': aspect[2],
            }
        });
    }

    getDataForExcel(dataObj, axisFor: string, overAllAspect = false) {
        let Result = [];
        let compareSource: any;

        Object.entries(dataObj).forEach(([key, value], index) => {
            if (axisFor == 'ratings' || axisFor == 'ratingOverAll') {
                compareSource = Ratings;
            } else if (axisFor == 'reviews' || axisFor == 'reviewOverAll') {
                compareSource = this.limitedMonthNames;
            } else if (axisFor == 'sentiments' || axisFor == 'sentimentOverAll') {
                compareSource = sentiments;
            }

            compareSource.forEach((val, ind) => {
                if (axisFor == 'ratings' || axisFor == 'ratingOverAll') {
                    Result.push({
                        [overAllAspect == true ? 'Aspect' : 'Location']: overAllAspect ? key : this.getLocationDetail(key),
                        'Ratings': compareSource[ind],
                        'Ratings Count': Object.keys(value).indexOf(compareSource[ind]) != -1 ? parseInt(value[compareSource[ind]]) : 0
                    });
                } else if (axisFor == 'reviews' || axisFor == 'reviewOverAll') {
                    Result.push({
                        [overAllAspect == true ? 'Aspect' : 'Location']: overAllAspect ? key : this.getLocationDetail(key),
                        'Month': compareSource[ind],
                        'Reviews Count': Object.keys(value).indexOf(compareSource[ind]) != -1 ? parseInt(value[compareSource[ind]]) : 0
                    });
                } else if (axisFor == 'sentiments' || axisFor == 'sentimentOverAll') {
                    Result.push({
                        [overAllAspect == true ? 'Aspect' : 'Location']: overAllAspect ? key : this.getLocationDetail(key),
                        'Sentiment': compareSource[ind],
                        'Total Count': Object.keys(value).indexOf(compareSource[ind]) != -1 ? parseInt(value[compareSource[ind]]) : 0
                    });
                }
            })

        });
        return Result;
    }

    getLocationDetail(locationId: string | number): string {

        let location = this.locationMaster[locationId];
        return (location["Address"] ? location["Address"] + ', ' : '') + (location["city"] ? location["city"] + ', ' : '') + (location["zip"] ? location["zip"] : '')
    }
    ngOnDestroy() {

        //this.chart.destroy();
    }
}

