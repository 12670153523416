import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

const sessionTTL = 1200000; // 20 mins
// const sessionTTL = 60000; // 1 min
const BASE_URL = environment.BASE_URL();
const xtractiotUser ='xtractiotUser';
@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {
    
    private secretKey = environment.cryptoSecretKey;
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient,private alertService:AlertService) {
        
        let user = this.getWithExpiry(xtractiotUser);
        this.currentUserSubject = new BehaviorSubject<any>(user);
        this.currentUser = this.currentUserSubject.asObservable();
    }
    public get CurrentUserData(){
       return this.currentUser;
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }
    
    setCurrentUserValue(userInfo) {
         this.currentUserSubject.next(userInfo);
    }

    login(email: string, password: string) {

        let headers = new HttpHeaders();
        // {
        //     'Access-Control-Allow-Origin': 'http://localhost:4200',
        //     'Access-Control-Allow-Credentials': true });
        let options = { headers: headers };
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Credentials', 'true');

        return this.http.post<any>(`${BASE_URL}/login`, { email, password },options);
    }

    triggerForgetPassword(email: string) {
        return this.http.post<any>(`${BASE_URL}/triggerResetPassword`, { email });
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('xtractiotUser');
        this.currentUserSubject.next(null);
		
		localStorage.removeItem('fbToken');//Added By Aravindh for FB
		localStorage.removeItem('fbId');//Added By Aravindh for FB
		localStorage.removeItem('fbConnected');//Added By Aravindh for FB
    }

    setWithExpiry(key=xtractiotUser, dataObj): void  {
        const now = new Date()
        dataObj['expiry'] = now.getTime() + sessionTTL;
        localStorage.setItem(key, JSON.stringify(dataObj))
    }

    getWithExpiry(key=xtractiotUser) {
        const itemStr = localStorage.getItem(key)

        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date();

        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            return null
        }
        return item;
    }

    updateLoginSessionExpiry(){
        let user = localStorage.getItem(xtractiotUser);
        if(user){
            let oldUserObj = JSON.parse(user);
            const now = new Date();
            oldUserObj['expiry'] = now.getTime() + sessionTTL;
            localStorage.setItem(xtractiotUser, JSON.stringify(oldUserObj))
        }
    }

    storeRememberMeData(email: string, password: string): void {
        const userCreds = {
            email: email,
            password: password
        };
        localStorage.setItem('xtractiotUserRemember', JSON.stringify(userCreds));
    }

    removeRememberMeData(): void {
        localStorage.removeItem('xtractiotUserRemember');
    }

    getRememberMeData() {

        return JSON.parse(localStorage.getItem('xtractiotUserRemember'));
    }
    

    encrypt(textToEncrypt: string): string {
        return CryptoJS.SHA1(textToEncrypt).toString(CryptoJS.enc.Hex); 
    }


    socialMedialogin(email: string, name: string) {

        let headers = new HttpHeaders();
        // {
        //     'Access-Control-Allow-Origin': 'http://localhost:4200',
        //     'Access-Control-Allow-Credentials': true });
        let options = { headers: headers };
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Credentials', 'true');

        return this.http.post<any>(`${BASE_URL}/socialmedia_login`, { email, name },options);
    }
  
}