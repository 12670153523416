import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { shareReplay } from 'rxjs/operators';
const BASE_URL = environment.BASE_URL();


@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    register(user: any) {
        return this.http.post<any>(`${BASE_URL}/signup`, user).pipe(shareReplay(1));
    }

    resetPassword(user: any) {
        return this.http.post<any>(`${BASE_URL}/doResetPassword`, user).pipe(shareReplay(1));
    }

    getUserDetails() {
        return this.http.get<any[]>(`/users`).pipe(shareReplay(1));
    }

    delete(id: number) {
        return this.http.delete(`/users/${id}`).pipe(shareReplay(1));
    }
    emailverification(tokenInfo: any){

        return this.http.post<any>(`${BASE_URL}/emailverification`, tokenInfo).pipe(shareReplay(1));
    }

    loadCounDialCo() {
        return this.http.get<any>(`${BASE_URL}/countrydialcodes`).pipe(shareReplay(1));
    }


} 