import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError, from } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize, concatMap } from 'rxjs/operators';
// import * as data from './assets/sample.json';

// array in local storage for registered users
let users = JSON.parse(localStorage.getItem('users')) || [];
const accountsKey = 'facebook-accounts';
let accounts = JSON.parse(localStorage.getItem(accountsKey)) || [];
@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url, method, headers, body } = request;

        // wrap in delayed observable to simulate server api call
        return of(null)
            .pipe(mergeMap(handleRoute))
            .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
            .pipe(delay(500))
            .pipe(dematerialize());

        function handleRoute() {
            switch (true) {
                case url.endsWith('/apimaster/api/v1/login') && method === 'POST':
                    return authenticate();
                case url.endsWith('/apimaster/api/v/signup') && method === 'POST':
                    return register();
                case url.endsWith('/apimaster/api/v1/users') && method === 'GET':
                    return getUsers();
                    case url.endsWith('/apimaster/api/v1/settingsPage') && method === 'GET':
                    return getSettingData();
                case url.match(/\/users\/\d+$/) && method === 'DELETE':
                    return deleteUser();
                case url.endsWith('/accounts/authenticate') && method === 'POST':
                    return facebook_authenticate();
                case url.endsWith('/accounts') && method === 'GET':
                    return getAccounts();
                case url.match(/\/accounts\/\d+$/) && method === 'GET':
                    return getAccountById();
                case url.match(/\/accounts\/\d+$/) && method === 'PUT':
                    return updateAccount();
                case url.match(/\/accounts\/\d+$/) && method === 'DELETE':
                    return deleteAccount();					
                default:
                    // pass through any requests not handled above
                    return next.handle(request);
            }    
        }

        function getSettingData(){
            return ok('empty data from fake server');
        }

        // route functions

        function authenticate() {
            //console.log("authenticate");
             const { username, password } = body;
          //  console.log("body name:",username," password:",password);
            const user = users.find(x => x.username === username && x.password === password);
            if (!user) {
          //      console.log("invalid user");
                return error('username or password is incorrect');
            }
           // console.log("valid user!!!!!!");
            return ok({
                success:true,
                data:[{
                    id: user.id,
                    username: user.username,
                    email: user.email,
                    roleid:1
                }]
            })
        }

        function register() {
           // console.log("reginstering new user...");
            const user = body

            if (users.find(x => x.username === user.username)) {
               // console.log('username "' + user.username + '" is already taken');
                return error('username "' + user.username + '" is already taken')
            }

            user.id = users.length ? Math.max(...users.map(x => x.id)) + 1 : 1;
            users.push(user);
           // console.log('user '+user);
            localStorage.setItem('users', JSON.stringify(users));
            // console.log('user '+user);
            return ok({success:true,message:'registration completed..'});
        }

        function getUsers() {
            // if (!isLoggedIn()) return unauthorized();
            return ok(users);
        }

        function deleteUser() {
            if (!isLoggedIn()) return unauthorized();

            users = users.filter(x => x.id !== idFromUrl());
            localStorage.setItem('users', JSON.stringify(users));
            return ok();
        }
		
		//Facebook start- Added by Aravindh - start
        function facebook_authenticate() {
		
		
            const { accessToken } = body;

			//User Data Start
            return from(new Promise(resolve => {
                fetch(`https://graph.facebook.com/v8.0/me?access_token=${accessToken}`)
                    .then(response => resolve(response.json()));
            })).pipe(concatMap((data: any) => {
			
				//console.log("USER data");
				//console.log(data);
				localStorage.setItem('fbId', data.id);
				
                if (data.error) return facebook_unauthorized(data.error.message);
				
                let account = accounts.find(x => x.facebookId === data.id);
                if (!account) {
                    // create new account if first time logging in
                    account = {
                        id: newAccountId(),
                        facebookId: data.id,
                        name: data.name,
                        extraInfo: `This is some extra info about ${data.name} that is saved in the API`
                    }
                    accounts.push(account);
                    localStorage.setItem(accountsKey, JSON.stringify(accounts));
                }

                return ok({
                    ...account,
                    token: generateJwtToken(account)
                });
            }));
			//User Data END
        }

        function getAccounts() {
            if (!isLoggedIn()) return unauthorized();
            return ok(accounts);
        }

        function getAccountById() {
            if (!isLoggedIn()) return unauthorized();

            let account = accounts.find(x => x.id === idFromUrl());
            return ok(account);
        }

        function updateAccount() {
            if (!isLoggedIn()) return unauthorized();

            let params = body;
            let account = accounts.find(x => x.id === idFromUrl());

            // update and save account
            Object.assign(account, params);
            localStorage.setItem(accountsKey, JSON.stringify(accounts));

            return ok(account);
        }

        function deleteAccount() {
            if (!isLoggedIn()) return unauthorized();

            // delete account then save
            accounts = accounts.filter(x => x.id !== idFromUrl());
            localStorage.setItem(accountsKey, JSON.stringify(accounts));
            return ok();
        }
        function newAccountId() {
            return accounts.length ? Math.max(...accounts.map(x => x.id)) + 1 : 1;
        }		
        function generateJwtToken(account) {
            // create token that expires in 15 minutes
            const tokenPayload = { 
                exp: Math.round(new Date(Date.now() + 15*60*1000).getTime() / 1000),
                id: account.id
            }
            return `fake-jwt-token.${btoa(JSON.stringify(tokenPayload))}`;
        }	
        function facebook_unauthorized(message = 'Unauthorized') {
            return throwError({ status: 401, error: { message } })
                .pipe(materialize(), delay(500), dematerialize());
        }		
		//Facebook end Added by Aravindh - end
        // helper functions

        function ok(body?) {
            return of(new HttpResponse({ status: 200, body }))
        }

        function error(message) {
            return throwError({ error: { message } });
        }

        function unauthorized() {
            return throwError({ status: 401, error: { message: 'Unauthorised' } });
        }

        function isLoggedIn() {
            return headers.get('Authorization') === 'Bearer fake-jwt-token';
        }

        function idFromUrl() {
            const urlParts = url.split('/');
            return parseInt(urlParts[urlParts.length - 1]);
        }
    }
}

export const fakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};