import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './guards/auth.guard';
import { CustomPreloadService } from './services/custom-preload.service';


const routes: Routes = [
  
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        data: { preload: false },
        loadChildren: () => import('./layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule)
      }
    ]
  },
 {
    path: "",
    component:DefaultLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        data: { preload: true },
        loadChildren: () => import('./layouts/default-layout/default-layout.module').then(m => m.DefaultLayoutModule)
      }
    ]
  },
];

@NgModule({
  imports: [CommonModule, BrowserModule,RouterModule.forRoot(routes, {preloadingStrategy:CustomPreloadService})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
