import { Component, OnInit } from '@angular/core';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { AuthenticationService } from 'src/app/services';
import { Router,ActivatedRoute  } from '@angular/router';
import { userInfo } from 'os';
import { CookieService } from 'ng2-cookies';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  image : any
  userid: any
  enableAllPages:boolean = false;
  imageUrl = "./../../../assets/img/";
  cookiee : string
  default : boolean;
  isUserGuide: boolean; //Added by Aravindh
  constructor(
    private cookie: CookieService,
    private router: Router,  
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService) { }
  
  ngOnInit() {
	this.isUserGuide=false; //Added by Aravindh
    this.cookiee = this.cookie.get('buzz');
    this.imageUrl += this.cookiee+'.png';

    this.authenticationService.CurrentUserData.subscribe(user=>{
      
      this.enableAllPages = user && user.enableAllPages == true ? true : false;
console.log(this.cookiee,'cookiee value');

//Added by Aravindh start
if(this.router.url=="/userguide")
{
	this.isUserGuide=true;
}
else
{
	this.isUserGuide=false;
}
//Added by Aravindh end
      if(this.cookiee == "default"){
        this.default = true
     }else{
      this.default = false

     }
     
     
    
    })
   
  }
  focusdivSideBar(id){
  
	for(let i=1;i<=10;i++)
	{
		document.getElementById("li_"+i).classList.remove("active");
	}
	const idArray = id.split("_");
	document.getElementById("li_"+idArray[1]).classList.add("active");
	console.log(id);
	document.getElementById(id).scrollIntoView();
  }
        
}
