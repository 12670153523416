<!-- <div id ="sideBar" [ngClass]="toggleClass ==false ? 'left-section' : 'left-section close-left' "> -->
<style>
#sidebar
{
height: calc(100% - 75px) !important; 
}
</style>
<div id ="sideBar"  class="left-section">
  <div class="logo-section" *ngIf="default"> 
    <a class="navbar-brand" href="#"><img [src]= "imageUrl" class="logo"></a> <a class="toggle-switch m-0 d-block d-sm-none" href="#">
      <span class="switch-left" style="display: none;"><i class="las la-times"></i></span></a></div>
  <aside id="sidebar">
    <ul id="sidemenu" class="sidebar-nav" *ngIf="!isUserGuide">
      <li [ngClass]="{'disabled-route' : enableAllPages==false}" routerLinkActive="active"><a [routerLink]="['/dashboard']"><i class="las la-home"  data-toggle="popover" data-placement="left" data-content="Dashboard"></i> <span>Dashboard</span> </a> </li>
      <li [ngClass]="{'disabled-route' : enableAllPages==false}" routerLinkActive="active"><a  [routerLink]="['/reviews']"><i class="lar la-star" data-toggle="popover" data-placement="right" data-content="Review"></i> <span>Review</span> </a> </li>
      <!-- <li [ngClass]="{'disabled-route' : enableAllPages==false}" routerLinkActive="active"><a [routerLink]="['/businessKPI']"><i class="lar la-lightbulb" data-toggle="popover" data-placement="right" data-content="Business Aspects"></i> <span>Business Aspects</span> </a> </li> -->
     <!--  <li [ngClass]="{'disabled-route' : enableAllPages==false}"><a [routerLink]=""><i class="las la-medal" data-toggle="popover" data-placement="right" data-content="Competitors"></i> <span>Competitors</span> </a> </li>
      <li [ngClass]="{'disabled-route' : enableAllPages==false}"><a [routerLink]=""><i class="las la-icons" data-toggle="popover" data-placement="right" data-content="Ticketing"></i> <span>Ticketing</span> </a> </li>
      <li [ngClass]="{'disabled-route' : enableAllPages==false}"><a [routerLink]=""><i class="las la-clipboard-list" data-toggle="popover" data-placement="right" data-content="Survey"></i> <span>Survey</span> </a> </li>
      <li [ngClass]="{'disabled-route' : enableAllPages==false}"><a [routerLink]=""><i class="las la-chart-bar" data-toggle="popover" data-placement="right" data-content="Reports"></i> <span>Reports</span> </a> </li> -->
      <li [ngClass]="{'disabled-route' : enableAllPages==false}" routerLinkActive="active"><a [routerLink]="['/post']"><i class="las las la-share-alt" data-toggle="popover" data-placement="right" data-content="Post"></i> <span>Post</span> </a> </li>
     <!-- <li routerLinkActive="active"><a [routerLink]="['/settings']"><i class="las la-cog" data-toggle="popover" data-placement="right" data-content="Settings"></i> <span>Settings</span> </a> </li>-->
      <!-- <li [ngClass]="{'disabled-route' : enableAllPages==false}"><a [routerLink]=""><i class="lar la-user-circle" data-toggle="popover" data-placement="right" data-content="Account"></i> <span>Account</span> </a> </li> -->
	  <li routerLinkActive="active"><a [routerLink]="['/source']"><i class="las la-cog" data-toggle="popover" data-placement="right" data-content="Settings"></i> <span>Source & Aspects</span> </a> </li>
	  <li routerLinkActive="active"><a [routerLink]="['/account']"><i class="las la-cog" data-toggle="popover" data-placement="right" data-content="Settings"></i> <span>Account</span> </a> </li>
    </ul>
	<ul id="ugsidemenu" class="sidebar-nav" *ngIf="isUserGuide">
       <li id="li_1" class="active"> <a href="javascript:void(0)" (click) = "focusdivSideBar('r_1')"> What is BuzzSense?</a></li>
       <li id="li_2"> <a href="javascript:void(0)" (click) = "focusdivSideBar('r_2')"> BuzzSense Concepts</a></li>
	   
       <li id="li_3"> <a href="javascript:void(0)" (click) = "focusdivSideBar('r_3')"> Dashboard</a></li>
       <li id="li_4"> <a href="javascript:void(0)" (click) = "focusdivSideBar('r_4')"> Business Aspects</a></li>
		<li id="li_5"> <a href="javascript:void(0)" (click) = "focusdivSideBar('r_5')"> Geographic reports</a></li>
		<li id="li_6"> <a href="javascript:void(0)" (click) = "focusdivSideBar('r_6')"> Review Module</a></li>		  
	   
       <li id="li_7"> <a href="javascript:void(0)" (click) = "focusdivSideBar('r_7')"> Get started with BuzzSense</a></li>
 	   
       <li id="li_8"> <a href="javascript:void(0)" (click) = "focusdivSideBar('r_8')"> Signup</a></li>
	   
       <li id="li_9"> <a href="javascript:void(0)" (click) = "focusdivSideBar('r_9')"> Step 1: Complete your account profile</a></li>
		<li id="li_10"> <a href="javascript:void(0)" (click) = "focusdivSideBar('r_10')"> Step 2: Complete the source and aspects page</a></li>
			
	   
	</ul>
  </aside>
</div>